import React from "react"
import Logo from "../logo"
import NavDrawer from "./navdrawer"
import { Link } from "gatsby"

// Styles
import styles from "../../styles/components/navigation/header.module.scss"

const Header = () => {
  const isHomePage =
    typeof window !== "undefined" && window?.location?.pathname === "/"

  return (
    <div id="top">
      <header
        className={`${styles.header} ${isHomePage ? styles.transparent : ""}`}
      >
        <ul className={styles.navbar}>
          <Link to="/" className={styles.logo}>
            <Logo />
          </Link>
          <Link to="/" className={styles.logoMobile}>
            <Logo />
          </Link>

          <div className={styles.navdrawer}>
            <NavDrawer />
          </div>

          <div className={styles.links}>
            <li>
              <Link to="/" activeClassName={styles.active}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" activeClassName={styles.active}>
                About
              </Link>
            </li>

            <li>
              <Link to="/faq" activeClassName={styles.active}>
                FAQ's
              </Link>
            </li>

            <li>
              <Link to="/articles" activeClassName={styles.active}>
                Articles
              </Link>
            </li>

            <li>
              <Link to="/videos" activeClassName={styles.active}>
                Videos
              </Link>
            </li>

            <li>
              <Link to="/products" activeClassName={styles.active}>
                Products
              </Link>
            </li>
            <li>
              <Link to="/loans" activeClassName={styles.active}>
                Loans
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName={styles.active}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/quote" activeClassName={styles.active}>
                Quote
              </Link>
            </li>
          </div>
        </ul>
      </header>
    </div>
  )
}

export default Header
