import React from "react"
import { FaFacebookF, FaInstagram } from "react-icons/fa"

// Styles
import styles from "../styles/components/follow.module.scss"

export default function Follow() {
  return (
    <div className={styles.main}>
      <a
        href="https://www.facebook.com/sunterra.lk"
        target="blank"
        className={styles.link}
      >
        <div className={styles.icon}>
          <FaFacebookF />
        </div>
      </a>
      <a
        href="https://www.instagram.com/sunterra.lk/"
        target="blank"
        className={styles.link}
      >
        <div className={styles.icon}>
          <FaInstagram />
        </div>
      </a>
    </div>
  )
}
