import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Logo from "../logo-white"
import { Link } from "gatsby"
import {
  FaHome,
  FaInfo,
  FaStar,
  FaPhone,
  FaFile,
  FaNewspaper,
} from "react-icons/fa"
import Follow from "../follow"

const styles = {
  list: {
    width: 250,
  },
  paper: {
    background: "#1a1a1a",
    top: 0,
  },
}

class TemporaryDrawer extends React.Component {
  state = {
    left: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes } = this.props
    const sideList = (
      <div className="navdrawer">
        <div className="navdrawer__logo">
          <Logo />
        </div>
        <div className={classes.list}>
          <List>
            <Link to="/">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span>
                    <FaHome />
                  </span>
                  Home
                </h3>
              </ListItem>
            </Link>

            <Link to="/about">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span>
                    <FaInfo />
                  </span>
                  About
                </h3>
              </ListItem>
            </Link>

            <Link to="/articles">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span>
                    <FaNewspaper />
                  </span>
                  Articles
                </h3>
              </ListItem>
            </Link>

            <Link to="/products">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span>
                    <FaStar />
                  </span>
                  Products
                </h3>
              </ListItem>
            </Link>

            <Link to="/loans">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span>
                    <FaStar />
                  </span>
                  Loans &amp; Schemes
                </h3>
              </ListItem>
            </Link>

            <Link to="/contact">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span>
                    <FaPhone />
                  </span>
                  Contact
                </h3>
              </ListItem>
            </Link>

            <Link to="/quote">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span>
                    <FaFile />
                  </span>
                  Quote
                </h3>
              </ListItem>
            </Link>
          </List>
        </div>
        <div className="footer">
          <Follow />
        </div>
      </div>
    )

    return (
      <div>
        <div
          id="nav-icon"
          className={this.state.right ? "open" : "close"}
          onClick={this.toggleDrawer("right", true)}
          onKeyDown={this.toggleDrawer("right", true)}
          role="button"
          tabIndex={0}
        >
          <span />
          <span />
          <span />
        </div>
        <Drawer
          classes={{ paper: classes.paper }}
          anchor="left"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("right", false)}
            onKeyDown={this.toggleDrawer("right", false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    )
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TemporaryDrawer)
